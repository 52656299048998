<template>
  <OutsideCard title="PASSWORD.RESET_CONFIRMATION_TITLE">
    {{ $t("PASSWORD.RESET_CONFIRMATION") }}
  </OutsideCard>
</template>

<script>
import OutsideCard from "@components/Layout/OutsideCard.vue";

export default {
  name: "ResetPasswordSuccess",

  components: {
    OutsideCard,
  },
};
</script>
